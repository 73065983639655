import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { map, get, getOr, compose } from 'lodash/fp';
import { useSelector } from 'react-redux';
import { Container } from '../Container/Container';
import { Conditional } from '../Conditional/Conditional';
import { LocalizedLink } from '../LocalizedLink/LocalizedLink';
import { String } from '../String/String';
import { connect } from '../../store/store';
import { setViewstate } from '../../store/actions/actions';
import { MARKET_SELECT, NEWSLETTER, LOGIN } from '../../constants/modals';
import {
  UspAreaRoot,
  UspText,
  UspRightList,
  ServiceItem,
  ChangeLanguage,
  ServiceLink,
  ServiceButton,
} from './styles';
import { WiggleAnimation } from '../WiggleAnimation/WiggleAnimation';
import * as GTM from '../../helpers/gtm';
import useUser from '../../hooks/useUser';
import { MY_PAGES_START_PAGE } from '../../constants/cmsTypes';
import { ICONS } from '../../constants/icons';
import { Icon } from '../Icon/Icon';
import { useRouter } from 'next/router';
import CountryFlag from './CountryFlag';

function createMarkup(html) {
  return { __html: html };
}

const UspAreaComponent = (props) => {
  const {
    navigation = {},
    scrollAtTop = true,
    isMobile = false,
    desktopUSPs = [],
    mobileUSPs = [],
    myPagesEnabled = false,
  } = props;

  const { topBarMenu } = navigation;

  const { locale } = useRouter();

  const myPages = useSelector((state) => state.myPages);
  const { user } = useUser();
  const [show, setShow] = useState(false);

  // Get desktop and mobile usps from bootstrap.
  const desktopStrings = desktopUSPs.map((usp) => usp.text);
  const mobileStrings = mobileUSPs.map((usp) => usp.text);

  // Get the current number of paths in our router history.
  const routerHistoryLength = useSelector(
    (store) => store?.viewState?.routerHistory?.length || 0
  );

  // Use current number of paths in our router history as a base for the current usp index.
  const numberOfUsps = isMobile ? mobileStrings.length : desktopStrings.length;
  const uspIndex = routerHistoryLength % numberOfUsps;

  // Get active usp string based on device.
  const activeUspString = isMobile
    ? mobileStrings[uspIndex]
    : desktopStrings[uspIndex];

  const showNewsletterModal = () => {
    GTM.newsletterManualOpenEvent();
    props.setViewstate('activeModal', NEWSLETTER);
  };

  const showMarketSelectModal = () => {
    props.setViewstate('activeModal', MARKET_SELECT);
  };

  const showLoginModal = () => {
    props.setViewstate('activeModal', LOGIN);
  };

  // const handleCheck = () => {
  //   checkLogin()
  //     .then(response => {
  //       console.log(response);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    if (
      isMobile &&
      (!activeUspString || (activeUspString && activeUspString.length < 1))
    ) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [activeUspString, isMobile]);

  if (!show) {
    return null;
  }

  return (
    <UspAreaRoot scrollAtTop={!scrollAtTop}>
      <Container noMargin>
        <Conditional show={!isMobile}>
          <UspText>
            <String id="topBarUspLabel" />
          </UspText>
        </Conditional>
        <Conditional
          show={isMobile && activeUspString && activeUspString.length > 0}
        >
          <UspText
            isMobile={isMobile}
            dangerouslySetInnerHTML={createMarkup(mobileStrings[uspIndex])}
          />
        </Conditional>
        <UspRightList isMobile={isMobile}>
          <ServiceItem>
            <WiggleAnimation>
              <ServiceLink onClick={() => showNewsletterModal()}>
                <String id="newsletterButtonLabel" />
              </ServiceLink>
            </WiggleAnimation>
          </ServiceItem>
          {locale && (
            <ServiceItem>
              <ChangeLanguage onClick={() => showMarketSelectModal()}>
                <CountryFlag />
              </ChangeLanguage>
            </ServiceItem>
          )}
          {map(
            (serviceItem) => (
              <ServiceItem key={`service-menu-item_${serviceItem.id}`}>
                <LocalizedLink
                  as={serviceItem.id}
                  page={serviceItem.type}
                  data={{ id: serviceItem.id }}
                >
                  <ServiceLink>{serviceItem.title}</ServiceLink>
                </LocalizedLink>
              </ServiceItem>
            ),
            topBarMenu
          )}
          {myPagesEnabled && (
            <ServiceItem>
              {user && user?.customerId !== '_everyone' ? (
                <LocalizedLink
                  as={myPages?.data?.redirectUrl || 'my-pages'}
                  page={MY_PAGES_START_PAGE}
                  data={{ id: myPages?.data?.redirectUrl || 'my-pages' }}
                >
                  <ServiceLink>
                    <String id="MyPagesMyAccount" />
                  </ServiceLink>
                </LocalizedLink>
              ) : (
                <ServiceButton type="button" onClick={showLoginModal}>
                  <Icon
                    icon={ICONS.USER}
                    size={18}
                    pathSettings={{
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      fill: 'black',
                      stroke: 'black',
                      strokeWidth: '.2',
                      strokeLinecap: 'round',
                    }}
                    mr={6}
                  />
                  <String id="MyPagesLogin" />
                </ServiceButton>
              )}
            </ServiceItem>
          )}
        </UspRightList>
      </Container>
    </UspAreaRoot>
  );
};

UspAreaComponent.propTypes = {
  setViewstate: PropTypes.func.isRequired,
  scrollAtTop: PropTypes.bool,
  navigation: PropTypes.object,
  isMobile: PropTypes.bool,
  country: PropTypes.string,
  language: PropTypes.string,
  desktopUSPs: PropTypes.array,
  mobileUSPs: PropTypes.array,
  myPagesEnabled: PropTypes.bool,
};

export const UspArea = compose(connect)(
  UspAreaComponent,
  { setViewstate },
  (store) => ({
    navigation: get(['bootstrap', 'data', 'navigation'], store),
    mobileUSPs: getOr([], ['bootstrap', 'data', 'mobileUSPs'], store),
    desktopUSPs: getOr([], ['bootstrap', 'data', 'desktopUSPs'], store),
    isMobile: get('viewState.isMobile', store),
    country: get('viewState.country', store),
    language: get('viewState.language', store),
    activeModal: get('viewState.activeModal', store),
  })
);
